<template>
  <div class="box simple-device-card p-2 pl-5 m-0">
    <div class="columns m-0 p-0 is-align-items-center">
      <div class="column p-0 is-1">
        <div class="is-size-7 has-text-grey-light">Device Type</div>
        <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.type }}</div>
      </div>
      <div class="column p-0 is-1">
        <div class="is-size-7 has-text-grey-light">Device Name</div>
        <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.name }}</div>
      </div>
      <div class="column p-0 is-4 mr-3 mt-2">
        <b-field label="Project Location - lat,lng format *" label-position="on-border">
          <b-input ref="location" @input="onLocationChanged"
                   placeholder="Device location - lat,lng format *" rounded v-model="location"
                   type="text" required></b-input>
        </b-field>
      </div>
      <div class="column p-0 mt-2">
        <b-field>
          <b-input size="is-small" @input="onInstructionChanged" v-model="instruction" placeholder="Installation Instruction"
                   rounded></b-input>
        </b-field>
        <b-field>
          <b-input size="is-small" @input="onDecommissionInstructionChanged" v-model="decommissionInstruction"
                   placeholder="Decommission Instruction" rounded></b-input>
        </b-field>
      </div>
      <div class="column p-0 is-flex is-1" style="max-width: 64px">
        <b-tooltip label="Remove Plan" class="ml-auto mr-2" :delay="1000" type="is-dark">
          <a @click="onDeleteClick">
            <b-icon icon="delete">
            </b-icon>
          </a>
        </b-tooltip>
      </div>
    </div>
    <hr class="mt-3 mb-1 has-background-grey-light mr-6" style="height: 1px">
    <collection-processing-input
        class="mt-4 mb-2"
        :show-copy-actions="false"
        :initial-collection-period="collectionPeriod"
        :initial-processing-period="processingPeriod"
        @collectionPeriodChanged="onCollectionPeriodChanged"
        @processingPeriodChanged="onProcessingPeriodChanged">
    </collection-processing-input>
  </div>
</template>

<script>

import CollectionProcessingInput
  from "@/modules/projects-module/components/collection-processing-input/collection-processing-input.vue";

export default {
  name: "add-device-card",
  components: {CollectionProcessingInput},
  props: {
    device: {
      type: Object,
      immediate: true,
    }
  },
  data() {
    return {
      instruction: null,
      decommissionInstruction: null,
      location: null,
      collectionPeriod: {dateRange: [], timeRanges: [{startTime: null, endTime: null}]},
      processingPeriod: {dateRange: [], timeRanges: [{startTime: null, endTime: null}]}

    }
  },
  mounted() {
    console.log('MOUNTED')
    this.instruction = this.device.instruction
    this.decommissionInstruction = this.device.decommissionInstruction
    this.location = this.device.location.coordinates.toString()
  },
  methods: {
    onDeleteClick() {
      console.log('On delete site click')
      this.$emit('onDeviceDeleteClicked')
    },
    onLocationChanged(event) {
      let data = {
        device: this.device,
        location: event
      }
      this.$emit('onDeviceLocationChanged', data)
    },
    onInstructionChanged(event) {
      let data = {
        device: this.device,
        instruction: event
      }
      this.$emit('onDeviceInstructionChanged', data)
    },
    onDecommissionInstructionChanged(event) {
      let data = {
        device: this.device,
        instruction: event
      }
      this.$emit('onDeviceDecommissionInstructionChanged', data)
    },
    onCollectionPeriodChanged(event){
      //this.device.collectionPeriod = value
      //this.emitChanges()
      let data = {
        device: this.device,
        collectionPeriod: event
      }

      this.$emit('onDeviceCollectionPeriodChanged', data)
    },
    onProcessingPeriodChanged(event){
      let data = {
        device: this.device,
        processingPeriod: event
      }

      this.$emit('onDeviceProcessingPeriodChanged', data)
    },
  },
  watch: {
    device: {
      handler: function (newVal, oldVal) {
        console.log('Device changed:', this.device)
        this.location = this.device.location.coordinates.toString()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.simple-device-card {
  border-radius: 32px;
  background-color: #f7f7f7;
  box-shadow: 0 0.125em 0.125em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
}
</style>