<template>
  <gmap-map
      ref="googleMap"
      :center="mapCenter"
      @click="onMapClicked"
      :zoom="9"
      :heading="heading"
      :options="options"
      map-type-id="terrain"
      style="width: 100%"
      class="mapbox-container">

    <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                      @closeclick="onCloseInfoWindow">
    </gmap-info-window>

    <gmap-marker v-if="siteGMapLocation"
                 ref="marker"
                 :position="siteGMapLocation"
                 :icon="siteIcon"
                 :draggable="false"
                 :clickable="false">
    </gmap-marker>

    <my-google-marker v-for="(item, index) in devices" :key="index + item.siteName"
                      :item="item"
                      :map-rotation="heading"
                      @onMarkerClicked="markerClicked(item, index)"
                      :is-selected="item === selectedDevice"
                      @onUpdateCoordinates="onUpdateCoordinates"
                      @onRotateFinished="onRotationChanged"
                      :draw-mode="drawMode"></my-google-marker>
    <div slot="visible">
      <draw-controls v-if="drawControlsMode !== 'none'" style="margin-top: 48px"
                     @onDrawModeSelected="onDrawModeSelected"
                     :mode="drawControlsMode"></draw-controls>
      <transition name="fade">
        <map-edit-location v-if="selectedDevice && showEditLocation" :item="selectedDevice"
                           :key="selectedDevice.address"
                           @onMapEditAddressChanged="onMapEditAddressChanged"></map-edit-location>
      </transition>
    </div>
  </gmap-map>
</template>

<script>
import MyGoogleMarker from "@/modules/projects-module/components/my-google-marker/my-google-marker.vue";
import DrawControls from "@/modules/projects-module/components/draw-controls/draw-controls.vue";
import {bbox, lineString} from "@turf/turf";
import {DeviceType, getDeviceSvg, getGmapPopupContent, getSitePlanSvg, getSiteSvg, gMapStyles} from "@/utils/utils";
import MapEditLocation from "@/modules/projects-module/components/map-edit-location/map-edit-location.vue";

export default {
  name: "g-map-input",
  components: {MapEditLocation, DrawControls, MyGoogleMarker},
  props: {
    devices: {
      type: Array,
      default: () => {
      },
      immediate: true,
    },
    selectedDevice: {
      type: Object
    },
    selectedProject: {
      type: Object
    },
    drawControlsMode: {
      type: String,
      required: true
    },
    isMarkersSelectable: {
      type: Boolean,
      required: true
    },
    showEditLocation: {
      type: Boolean,
      default: false
    },
    siteLocation: {
      type: Object
    },
    siteName: {
      type: String
    }
  },
  data() {
    return {
      mapCenter: {lat: -37.80193, lng: 144.94444},
      mapData: [],
      mapZoom: 10,
      drawMode: null,
      heading: 0,
      options: {draggableCursor: 'default',styles: gMapStyles},
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: '',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -10
        }
      },
      siteIcon: null
    }
  },
  async mounted() {
    console.log('Gmap input devices: ', this.devices)
    await this.$gmapApiPromiseLazy();

    console.log('Map: ', this.$refs.googleMap)

    this.$refs.googleMap.$mapObject.addListener('projection_changed', () => {
      console.log('Map heading', this.$refs.googleMap.$mapObject.heading)
      this.heading = this.$refs.googleMap.$mapObject.heading
    });

    this.zoomMapToSelectedSiteOrProject(true)
    this.siteIcon = {
      url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(getSitePlanSvg(null, this.siteName)),
      anchor: {x: 24, y: 24},
    }
  },
  methods: {
    onCloseInfoWindow() {
      this.infoWinOpen = false
      this.zoomMapToSelectedSiteOrProject(true)
    },
    onMapEditAddressChanged(device) {
      this.$emit('onMapEditAddressChanged', device)
      setTimeout(() => {
        this.zoomMapToSelectedSiteOrProject(true)
      }, 300)
    },
    onDrawModeSelected(mode) {
      this.drawMode = mode
      if (this.drawMode === 'add') {
        this.options.draggableCursor = 'crosshair'
      } else if (this.drawMode === 'move') {
        this.options.draggableCursor ='move'
      } else if (this.drawMode === 'rotate') {
        this.options.draggableCursor = 'default'
      } else {
        this.options.draggableCursor =  'grab'
      }
      console.log('Draw mode', this.drawMode)
    },
    onMapClicked(event) {
      console.log('On map clicked', event.latLng.toJSON())
      let position = event.latLng.toJSON()
      console.log('On map clicked', position)
      if (this.drawMode === 'add') {
        this.$emit('onMapInputClicked', position)
      } else {
        this.$emit('onMapClicked', position)
        this.infoWinOpen = false
      }
    },
    markerClicked(device, index) {
      //console.log('Marker clicked map input:', device)
      if (!this.drawMode && this.isMarkersSelectable) {
        this.$emit('onMarkerClicked', device)
        if (device.objectType && device.objectType === 'site-plan') {
          return
        }
        this.toggleInfoWindow(device, index)
      }
    },
    toggleInfoWindow(device, idx) {
      this.infoWindowPos = {lat: device.location.coordinates[1], lng: device.location.coordinates[0]};
      this.infoOptions.content = getGmapPopupContent(device)

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    onUpdateCoordinates(event) {
      console.log('On update coordinates G map input:', event)
      this.$emit('onCoordinatesUpdated', event)
    },
    onRotationChanged(data) {
      this.$emit('onRotationChanged', data)
    },
    zoomMapToSelectedSiteOrProject(animate) {
      console.log('GMap, zoom to projects or sites')
      if (this.selectedProject != null) {
        //Zoom to selected site
        let coordinates = []
        console.log('GMap, zoom to projects or sites, selected device: ', this.selectedDevice)
        console.log('GMap, zoom to projects or sites, selected devices: ', this.devices)
        console.log('GMap, zoom to projects or sites, selected project: ', this.selectedProject)
        if (this.selectedDevice != null) {
          let center = {
            lat: this.selectedDevice.location.coordinates[1],
            lng: this.selectedDevice.location.coordinates[0]
          }
          this.$refs.googleMap.$mapObject.panTo(center);
          return

        } else {
          coordinates.push(...this.devices.map(device => {
            return device.location.coordinates
          }))
        }

        if (coordinates.length > 0) {
          if (coordinates.length === 1) {
            this.$refs.googleMap.$mapObject.panTo({lat: coordinates[0][1], lng: coordinates[0][0]});
          } else {
            let line = lineString(coordinates);
            let bb = bbox(line);
            console.log('Bounding box:', bb)
            let boundsGMap = new window.google.maps.LatLngBounds()
            boundsGMap.extend({lat: bb[1], lng: bb[0]})
            boundsGMap.extend({lat: bb[3], lng: bb[2]})
            console.log('LatLngBounds: ', boundsGMap)
            this.$refs.googleMap.$mapObject.panTo(boundsGMap.getCenter());
          }

        } else if (animate) {
          this.$refs.googleMap.$mapObject.panTo(this.mapCenter);
        }
      } else {
        this.$refs.googleMap.$mapObject.panTo(this.mapCenter);
      }
    },
  },
  computed: {
    siteGMapLocation() {
      if (this.siteLocation) {
        return {lat: this.siteLocation.coordinates[1], lng: this.siteLocation.coordinates[0]}
      }

      return null
    }
  },
  watch: {
    heading: function (val) {
      console.log('Heading: ', val)
    },
    selectedDevice() {
      console.log('Selected device changed')
      if (this.selectedDevice === null) {
        this.infoWinOpen = false
      }
      this.zoomMapToSelectedSiteOrProject(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.mapbox-container {
  width: 100%;
  height: 500px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-color: #D9D9D9;
}
</style>