<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="90%" scroll="keep" style="z-index: 101" @close="clearFields()">
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head" style="padding: 12px">
          <h6 class="is-size-5 ml-auto mr-auto pl-4 has-text-weight-semibold">Edit Site Plan</h6>
          <button
              type="button"
              class="delete"
              @click="show(false, '', deselectOnClose)"/>
        </header>
        <section class="modal-card-body p-0 pt-2 pl-2 pr-2" v-if="modalActive">
          <map-input ref="mapBoxInput" v-if="mapType === 'mapbox'"
                     @onMapInputClicked="onMapInputClicked"
                     @onCoordinatesUpdated="onCoordinatesUpdated"
                     @onRotationChanged="onRotationChanged"
                     @onMarkerClicked="onSelectSite"
                     :devices="[editedSite]"
                     :selected-device="selectedSite"
                     :selected-project="selectedProject"
                     draw-controls-mode="edit"
                     @onMapClicked="onMapClicked">

          </map-input>
          <g-map-input ref="gMapInput" v-if="mapType === 'gmap'" style="max-height: 45vh;"
                       @onMapInputClicked="onMapInputClicked"
                       @onCoordinatesUpdated="onCoordinatesUpdated"
                       @onRotationChanged="onRotationChanged"
                       @onMarkerClicked="onSelectSite"
                       :is-markers-selectable="true"
                       :devices="[editedSite]"
                       :selected-device="selectedSite"
                       :selected-project="selectedProject"
                       :show-edit-location="true"
                       :site-name="editedSite.siteName"
                       draw-controls-mode="move"
                       @onMapClicked="onMapClicked"
                       @onMapEditAddressChanged="onMapEditAddressChanged"
          ></g-map-input>
          <div class="ml-4 mr-4" style="margin-bottom: 200px">
            <div class="mt-5" v-if="modalActive">
              <div class="box simple-device-card p-2 pl-5 m-0 mt-2" :class="{'selected':selectedSite === editedSite}">
                <div class="columns m-0 p-0 is-align-items-center">
                  <div class="column p-0" @click="onSelectSite(editedSite)" style="cursor: pointer">
                    <div class="is-size-7 has-text-grey-light">Site Name</div>
                    <div class="is-size-6 has-text-weight-semibold has-text-black">{{ editedSite.siteName }}</div>
                  </div>
                  <div class="column p-0 mt-1">
                    <b-field grouped label="Installation date *" label-position="on-border">
                      <b-datepicker :mobile-native="false" expanded v-model="editedSite.installationDate"
                                    size="is-small" position="is-top-right">
                        <template v-slot:trigger>
                          <b-input expanded readonly rounded placeholder="Installation date *"
                                   :value="installationDateString()" type="text"
                                   required/>
                        </template>
                      </b-datepicker>
                    </b-field>
                  </div>
                  <div class="column p-0 mt-1 ml-1">
                    <b-field grouped label="Decommission date *" label-position="on-border">
                      <b-datepicker :mobile-native="false" expanded v-model="editedSite.decommissionDate"
                                    size="is-small" position="is-top-right">
                        <template v-slot:trigger>
                          <b-input expanded readonly rounded placeholder="Decommission date *"
                                   :value="decommissionDateString()" type="text"
                                   required/>
                        </template>
                      </b-datepicker>
                    </b-field>
                  </div>
                  <div class="column p-0 pr-2">
                    <b-field expanded>
                      <b-dropdown
                          expanded
                          v-model="editedSite.installationCrew"
                          multiple
                          @change="onInstallDropDownChanged"
                          aria-role="list">
                        <template #trigger>
                          <my-button
                              :key="installDropdownKey"
                              expanded
                              style="border-radius: 32px"
                              icon-right="menu-down">
                            <div class="is-flex" v-if="editedSite.installationCrew.length > 0">
                              <user-avatar v-for="user in editedSite.installationCrew" :user="user" :is-small="true"
                                           :key="user.id"></user-avatar>
                            </div>
                            <div v-else class="has-text-grey-light">
                              Installation crew
                            </div>

                          </my-button>
                        </template>
                        <b-dropdown-item v-for="user in users" :value="user" :key="user.id"
                                         aria-role="listitem">
                          <div class="is-flex m-0 p-0">
                            <div class="m-0 p-0">
                              <user-avatar :user="user" :is-small="true"></user-avatar>
                            </div>
                            <div class="m-0 ml-2 p-0">
                              <div class="is-size-7 has-text-weight-semibold" style="line-height: 1">{{ user.fullName }}</div>
                              <div class="is-size-7" style="line-height: 1"> {{user.partEmail}}</div>
                            </div>
                          </div>

                        </b-dropdown-item>
                      </b-dropdown>
                    </b-field>
                  </div>
                  <div class="column p-0 pr-2">
                    <b-field expanded>
                      <b-dropdown
                          expanded
                          v-model="editedSite.decommissionCrew"
                          @change="onDecommissionDropDownChanged"
                          multiple
                          aria-role="list">
                        <template #trigger>
                          <my-button
                              :key="decommissionDropdownKey"
                              expanded
                              style="border-radius: 32px"
                              icon-right="menu-down">
                            <div class="is-flex"
                                 v-if="editedSite.decommissionCrew && editedSite.decommissionCrew.length > 0">
                              <user-avatar v-for="user in editedSite.decommissionCrew" :user="user" :is-small="true"
                                           :key="user.id"></user-avatar>
                            </div>
                            <div v-else class="has-text-grey-light">
                              Decommission crew
                            </div>

                          </my-button>
                        </template>
                        <b-dropdown-item v-for="user in users" :value="user" :key="user.id"
                                         aria-role="listitem">
                          <div class="is-flex m-0 p-0">
                            <div class="m-0 p-0">
                              <user-avatar :user="user" :is-small="true"></user-avatar>
                            </div>
                            <div class="m-0 ml-2 p-0">
                              <div class="is-size-7 has-text-weight-semibold" style="line-height: 1">{{ user.fullName }}</div>
                              <div class="is-size-7" style="line-height: 1"> {{user.partEmail}}</div>
                            </div>
                          </div>

                        </b-dropdown-item>
                      </b-dropdown>
                    </b-field>
                  </div>
                </div>
<!--                <collection-processing-input ref="collectionProcessInputs"
                                             :show-copy-actions="false"
                                             :initial-collection-period="editedSite.collectionPeriod"
                                             :initial-processing-period="editedSite.processingPeriod"
                                              @collectionPeriodChanged="onCollectionPeriodChanged"
                                              @processingPeriodChanged="onProcessingPeriodChanged"
                ></collection-processing-input>-->
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot" style="padding: 0">
          <b-button class="is-primary is-fullwidth m-2" @click="onUpdateSite" rounded>
            UPDATE SITE
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MapInput from "@/components/map-input/map-input.vue";
import GMapInput from "@/components/g-map-input/g-map-input.vue";
import {
  DeviceType,
  getDate,
  getDateObjectFromMelbourneUtcToLocal,
  getDateRange,
  TaskType
} from "@/utils/utils";
import MyButton from "@/components/my-button/my-button.vue";
import UserAvatar from "@/components/avatar/avatar.vue";
import {getUpdateSitePlanPayload} from "@/utils/helper";

export default {
  name: "edit-site-plan",
  components: {UserAvatar, MyButton, MapInput, GMapInput},
  props: {
    site: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      isLoading: false,
      modalActive: false,
      deviceType: 'CAMERA',
      editedSite: null,
      selectedSite: null,
      deselectOnClose: false,
      clickedMarker: false,
      decommissionDropdownKey: 0,
      installDropdownKey: 0,
      refresh: false
    }
  },
  mounted() {
    console.log('Devices: ', this.devices)
    this.editedSite = JSON.parse(JSON.stringify(this.site))
    this.populateFields()
  },
  methods: {
    ...mapActions({
      getLocation: 'getLocationData',
      deselectAllDevices: 'projectsModule/deselectAllDevices',
      updateSitePlan: 'projectsModule/updateSitePlan'
    }),
    populateFields() {
      console.log('Populate fields: ', this.site)
      this.editedSite = JSON.parse(JSON.stringify(this.site))
      this.editedSite.objectType = 'site-plan'
      this.editedSite.location = this.editedSite.siteLocation


      let installationCrew = [];
      let decommissionCrew = [];
      let installationDate;
      let decommissionDate;

      this.editedSite.listOfPlanDevices.forEach(device => {
        let installTask = device.tasks.find(task => task.taskType === TaskType.INSTALLATION)
        let decommissionTask = device.tasks.find(task => task.taskType === TaskType.DECOMMISSION)

        if (installTask) {
          installationCrew = installTask.crew
          installationDate = installTask.date
        }

        if (decommissionTask) {
          decommissionCrew = decommissionTask.crew
          decommissionDate = decommissionTask.date
        }

      })

      this.editedSite.installationCrew = installationCrew.map(userId => this.getUserById(userId))
      this.editedSite.decommissionCrew = decommissionCrew.map(userId => this.getUserById(userId))
      this.editedSite.installationDate = installationDate ? getDateObjectFromMelbourneUtcToLocal(installationDate) : null
      this.editedSite.decommissionDate = decommissionDate ? getDateObjectFromMelbourneUtcToLocal(decommissionDate) : null

      //this.editedSite.collectionPeriod = getInputPeriod(this.editedSite.collectionPeriod)
      //this.editedSite.processingPeriod = getInputPeriod(this.editedSite.processingPeriod)


      console.log('Edited site: ', this.editedSite)
      console.log('Edited site installation: ', this.editedSite.installationDate)
    },
    show(show, action, deselectOnClose) {
      this.modalActive = show
      this.deselectOnClose = deselectOnClose
      this.populateFields()
      console.log('Show edit device:', this.deselectOnClose, this.editedSite)
      if (!this.modalActive) {
        this.clearFields()
      }
    },
    async onUpdateSite() {
      console.log('On update site:', this.editedSite)
      let payload = getUpdateSitePlanPayload(this.editedSite)
      console.log('On update devices payload: ', payload)
      this.isLoading = true
      let response = await this.updateSitePlan(payload)
      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        await this.clearFields()
        this.showToast('Site plan successfully updated', 'is-success')
        this.show(false)
      }
      this.isLoading = false

    },
    async onMapClicked(position) {
      console.log('On map clicked edit devices:', position)
      if (!this.clickedMarker || this.mapType === 'gmap') {
        this.selectedDevice = null
      }
      this.clickedMarker = false
      this.zoomToObject()
    },
    onRotationChanged(data) {
      console.log('On rotation changed', data)
    },
    onMapInputClicked(position) {
      console.log('On map input clicked:', position)
    },
    getAddressString(location) {
      if (location.address) {
        if (location.place.includes(location.address)) {
          return `${location.place}`
        } else {
          return `${location.address}, ${location.place}`
        }

      } else {
        this.$buefy.toast.open({
          message: 'Can\'t get address for selected location',
          duration: 4000,
          type: 'is-danger'
        })
        return 'unknown'
      }
    },
    parseAddress(location) {
      if (location.address) {
        let name = location.address
        return {address: name, place: location.place}
      } else {

        let name = 'Unknown'
        this.$buefy.toast.open({
          message: 'Can\'t get address for selected location',
          duration: 4000,
          type: 'is-danger'
        })

        return {address: name, place: location.place}
      }
    },
    async onCoordinatesUpdated(data) {
      console.log('On update coordinates: ', data)
      this.isLoading = true
      let location = await this.getLocation(data.coordinates)
      this.isLoading = false

      this.editedSite.location.coordinates = [data.coordinates.lng, data.coordinates.lat]
      let parsed = this.parseAddress(location)
      this.editedSite.siteName = parsed.address
      this.editedSite.siteLocationName = parsed.place && parsed.place !== 'unknown' ? parsed.place : parsed.address

      if (this.selectedSite && this.selectedSite._id === this.editedSite._id) {
        console.log('Device is selected')
        this.selectedSite = this.editedSite
      }
    },
    onMapEditAddressChanged(data) {
      console.log('On address changed:', data)
      this.editedSite.location.coordinates = data.location.coordinates

      let siteName = data.address ? data.address.split(',')[0] : 'Undefined'

      this.editedSite.address = data.address
      this.editedSite.siteName = siteName
      if (this.selectedSite && this.selectedSite._id === this.editedSite._id) {
        console.log('Device is selected')
        this.selectedSite = this.editedSite
      }
    },
    async clearFields() {
      console.log('Clear fields edit device', this.deselectOnClose)
      if (this.deselectOnClose) {
        await this.deselectAllDevices()
        this.selectedSite = null
        this.editedSite = null
      }
    },
    onSelectSite(site) {
      console.log('Site selected:', site)
      if (this.selectedSite && this.selectedSite._id === site._id) {
        this.selectedSite = null
      } else {
        this.selectedSite = site
        this.clickedMarker = true
      }
      this.zoomToObject()
    },
    click() {
      console.log('Clcked')
    },
    zoomToObject() {
      if (this.$refs.mapBoxInput) {
        this.$refs.mapBoxInput.zoomMapToSelectedSiteOrProject(true)
      } else if (this.$refs.gMapInput) {
        this.$refs.gMapInput.zoomMapToSelectedSiteOrProject(true)
      }
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    },
    getUserById(id) {
      return this.$store.getters.getUserById(id)
    },
    installationDateString() {
      return this.editedSite.installationDate ? getDate(this.editedSite.installationDate) : ''
    },
    decommissionDateString() {
      return this.editedSite.decommissionDate ? getDate(this.editedSite.decommissionDate) : ''
    },
    onInstallDropDownChanged() {
      this.installDropdownKey++
    },
    onDecommissionDropDownChanged() {
      this.decommissionDropdownKey++
    }
  },
  computed: {
    ...mapGetters({
      mapType: 'getMapType',
      selectedProject: 'projectsModule/getSelectedProject'
    }),
    DeviceType() {
      return DeviceType
    },
    users() {
      console.log('Allowed users:', this.$store.getters.getAllowedUsers)
      return this.$store.getters.getAllowedUsers
    }
  }
}
</script>

<style lang="scss" scoped>
.simple-device-card {
  border-radius: 32px;
  background-color: #f7f7f7;
  box-shadow: 0 0.125em 0.125em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
}

.selected {
  background-color: #e3e3e3;
}

.modal-tab-content {
  height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 24px;
  padding-right: 16px;
}

</style>