export default {
    setSelectedQaProject(state, project) {
        state.selectedQaProject = project
        if (project === null) {
            state.qaData = null
        }
    },
    setQueryData(state, queryData) {
        state.searchQueryData = queryData
        console.log('Set query data mutation: ', state.searchQueryData)
    },
    setSelectedQaDevice(state, device) {
        state.selectedQaDevice = device
    },
    setQaData(state, data) {
        state.qaData = data
    },
    setQaTableHeaderData(state, newData) {
        state.qaTableHeaderData = newData
        console.log('Table header data: ', state.qaTableHeaderData)
    },
    resetQaTableHeaderData(state) {
        state.qaTableHeaderData = {
            activeSorted: {
                field: '',
                order: ''
            },
            activeFilterField: '',
            filters: {avgSpeed: null, userClass: null, userType: null, eventAt:null}
        }
    },
    setQaValidationData(state, newData){
        if(state.selectedQaDevice && state.selectedQaDevice._id === newData.dataDeviceId){
            state.selectedQaDevice.qaValidation = newData
        }
    },
    setDataDeviceAvailableDates(state, data){
        if(state.selectedQaDevice && state.selectedQaDevice._id === data.dataDeviceId){
            if(data.responseData && data.responseData.availableDatesInTimeZone){
                state.selectedQaDevice.availableDatesInTimeZone = data.responseData.availableDatesInTimeZone
            }
        }
    },
    setQaLegendSelection(state, selection) {
        state.qaLegendSelection = selection
    }
}
