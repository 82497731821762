import { render, staticRenderFns } from "./g-map-qa.vue?vue&type=template&id=5d905a60&scoped=true"
import script from "./g-map-qa.vue?vue&type=script&lang=js"
export * from "./g-map-qa.vue?vue&type=script&lang=js"
import style0 from "./g-map-qa.vue?vue&type=style&index=0&id=5d905a60&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d905a60",
  null
  
)

export default component.exports