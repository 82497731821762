import Vue from "vue";
import {Role} from "@/utils/utils";

export default {
    /**
     * Get projects for specific user
     * @param commit
     * @param userId
     * @returns {Promise<*>}
     */
    getMe: async ({commit}) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/uac-me'
        };
        await Vue.prototype.$http(config).then(function (response) {
            console.log('Get me response: ', response.data)
            if (response.data) {
                commit('setMe', response.data)
            }
        }).catch(function (error) {
            console.log(error);
        });
    },
    getProjects: async ({commit, state, getters}) => {
        if (state.allProjects.length === 0) {
            if (getters.getUserRole === Role.ADMIN) {
                let config = {
                    method: 'get',
                    url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-projects/projects?includeActiveOnly=true'
                };
                await Vue.prototype.$http(config).then(function (response) {
                    commit('updateAllProjects', response.data)
                }).catch(function (error) {
                    console.log(error);
                });
            } else {
                let config = {
                    method: 'get',
                    url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-users/user-projects',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: {id: getters.getUserId, includeProjectDetails: true}
                };

                return await Vue.prototype.$http(config)
                    .then((response) => {
                        console.log('Get user projects response: ', response.data)
                        let projects = response.data.map(item => {
                            return item.project
                        })
                        commit('updateAllProjects', projects)
                        return response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
    },
    getUsers: async ({commit}) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/list-users-v3',
            data: {organisations: ['RTT']},

        };
        await Vue.prototype.$http(config).then(function (response) {
            console.log('All users: ', response.data)
            if (response.data) {
                console.log('Setting users')
                commit('setUsers', response.data)
            }
        }).catch(function (error) {
            console.log(error);
        });
    },
    downloadFile: async ({commit, state, getters}, data) => {
        let config = {
            method: 'GET',
            url: data.url,
            responseType: 'arraybuffer',
            onDownloadProgress: (progressEvent) => {
                let percent = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
                console.log('On progress event: ', percent)
                data.callback(percent)
            }
        };

        return await Vue.prototype.$http(config)
            .then((response) => {
                return response.data;
            })
            .catch(function (error) {
                console.log('Download file error', error)
            });
    },
    clearAllUserData: async ({commit}) => {
        //We must clear the data in all modules
        commit('removeUserData')
        commit('projectsModule/clearAllData')
        commit('uacModule/clearAllData')
    },
    setMapStyle: async ({commit}, newStyle) => {
        commit('setMapStyle', newStyle)
    },
    getLocationData: async ({commit, state}, coordinates) => {
        if (state.mapType === 'gmap') {
            console.log('Get place GMAP')
            let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.lat},${coordinates.lng}&result_type=street_address&key=${Vue.prototype.$env.VUE_APP_GOOGLE_MAPS_KEY}`

            return await Vue.prototype.$http({
                method: 'GET',
                url: url,
            }).then(function (response) {
                if (response.status === 200) {
                    if (response.data) {
                        console.log('Gmap geo response: ', response.data)
                        if (response.data.status === 'OK' && response.data.results.length > 0) {
                            let formattedAddress = response.data.results[0].formatted_address
                            let placeAddress = formattedAddress ? formattedAddress.split(',')[0] : ''
                            return {address: placeAddress, place: formattedAddress}
                        }
                    }
                }
                return {address: null, place: null}
            })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            let url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'
                + coordinates.lng + ',' + coordinates.lat +
                '.json?access_token=' + Vue.prototype.$env.VUE_APP_ACCESS_TOKEN;

            return await Vue.prototype.$http({
                method: 'GET',
                url: url,
            }).then(function (response) {
                if (response.status === 200) {
                    if (response.data) {
                        if (response.data.features != null && response.data.features.length > 0) {
                            console.log('Address: ', response.data.features)
                            let address = response.data.features.find(feature => feature.place_type.includes('address'))
                            let place = response.data.features.find(feature => feature.place_type.includes('locality'))
                            let placeName = place && place.place_name ? place.place_name : ''
                            let placeAddress = address && address.place_name ? address.place_name.split(',')[0] : ''
                            return {address: placeAddress, place: placeName}
                        }
                    }
                }
                return {address: null, place: null}
            })
                .catch(function (error) {
                    console.log(error);
                });
        }


    },
    getLocationByName: async ({commit, state}, query) => {
        if (state.mapType === 'gmap') {
            let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${query}&result_type=street_address&key=${Vue.prototype.$env.VUE_APP_GOOGLE_MAPS_KEY}`
            return await Vue.prototype.$http({
                method: 'GET',
                url: url,
            }).then(function (response) {
                if (response.status === 200) {
                    if (response.data && response.data.results) {
                        return response.data.results
                    }
                }
                return []
            })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            console.log('Get location by name, MapBox')
        }
    },
    getSettings: async ({commit}) => {

        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/system-settings-v3',
            headers: {
                'content-type': 'application/json'
            }
        };

        await Vue.prototype.$http(config)
            .then((response) => {
                commit('setSettings', response.data)
                return response.data
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    addNewConfiguration: async ({commit, dispatch}, configuration) => {
        const options = {
            method: 'POST',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/configuration-template-v3',
            headers: {
                'content-type': 'application/json'
            },
            data: configuration
        };
        return await Vue.prototype.$http(options).then(async function (response) {
            console.log('Add configuration template response: ', response)
            await dispatch('getSettings')
            return response.data

        }).catch(function (error) {
            console.log(error);
        });
    },
    editConfigurationTemplate: async ({commit, dispatch}, configuration) => {
        const options = {
            method: 'PUT',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/configuration-template-v3',
            headers: {
                'content-type': 'application/json'
            },
            data: configuration
        };
        return await Vue.prototype.$http(options).then(async function (response) {
            console.log('Edit configuration template response: ', response)
            await dispatch('getSettings')
            return response.data

        }).catch(function (error) {
            console.log(error);
        });
    },
    addStatus: async ({commit, dispatch}, payload) => {
        const options = {
            method: 'POST',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/device-status-v3',
            headers: {
                'content-type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(options).then(async function (response) {
            console.log('Add status response: ', response)
            await dispatch('getSettings')
            return response.data

        }).catch(function (error) {
            console.log(error);
        });
    },
    editStatus: async ({commit, dispatch}, payload) => {
        const options = {
            method: 'PUT',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/device-status-v3',
            headers: {
                'content-type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(options).then(async function (response) {
            console.log('Edit status response: ', response)
            await dispatch('getSettings')
            return response.data

        }).catch(function (error) {
            console.log(error);
        });
    },
    addNewTemplate: async ({commit, dispatch}, data) => {

        let options = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/template-v3',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await Vue.prototype.$http(options).then(async function (response) {
            console.log('Add template response: ', response)
            await dispatch('getSettings')
            return response.data

        }).catch(function (error) {
            console.log(error);
        });
    },
    updateTemplate: async ({commit, dispatch}, payload) => {

        let options = {
            method: 'put',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/template-v3',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };

        return await Vue.prototype.$http(options).then(async function (response) {
            console.log('Update template response: ', response)
            await dispatch('getSettings')
            return response.data

        }).catch(function (error) {
            console.log(error);
        });
    },
    deleteTemplate: async ({commit, dispatch}, id) => {

        let data = {ids: [id]}

        let options = {
            method: 'delete',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/template-v3',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await Vue.prototype.$http(options).then(async function (response) {
            console.log('Delete template response: ', response)
            if (response.data && response.data.length > 0) {
                await dispatch('getSettings')
            }
            return response.data

        }).catch(function (error) {
            console.log(error);
        });
    },
    setMqttStatus: async ({commit}, status) => {
        commit('setMqttStatus', status)
    },
}

