<template>
  <apex-chart type="line" height="400" :options="chartOptions" :series="series"></apex-chart>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "speed-distribution-chart",
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: 400,
          type: 'line',
          fontFamily: 'Barlow'
        },
        stroke: {
          width: [0, 4]
        },
        title: {
          text: 'Speed Bin Distribution'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        plotOptions: {
          bar: {
            columnWidth: '98%',
          },
        },
        labels: [],
        yaxis: [{
          title: {
            text: 'Vehicle count',
          },

        }, {
          opposite: true,
          title: {
            text: 'Vehicle count 1'
          }
        }]
      },
    }
  },
  mounted() {
    this.setChartData()
  },
  methods:{
    setChartData(){
      let series = []
      let categories = []

      if(this.selectedDevice && this.selectedDevice.chartsData && this.selectedDevice.chartsData.vehSpeedData){
        let speedData = this.selectedDevice.chartsData.vehSpeedData

        console.log('SpeedData: ',speedData)
        if(speedData.bins && speedData.binCounts){
          series.push({
                name: 'Vehicle count',
                type: 'column',
                data: speedData.binCounts
              }
          )
          categories = speedData.bins.map(bean => `${bean + '-' + (bean + 5)}`)
        }
      }

      series.push(
          {
            name: 'Vehicle count 1',
            type: 'line',
            data: series[0].data
          }
      )

      console.log('Series: ', series)
      console.log('Categories: ', categories)


      this.series = series
      this.chartOptions =  {
        chart: {
          height: 400,
              type: 'line',
              fontFamily: 'Barlow'
        },
        stroke: {
          width: [0, 4]
        },
        title: {
          text: 'Speed Bin Distribution'
        },
        dataLabels: {
          enabled: true,
              enabledOnSeries: [1]
        },
        plotOptions: {
          bar: {
            columnWidth: '98%',
          },
        },
        labels: categories,
            yaxis: [{
          title: {
            text: 'Vehicle count',
          },

        }, {
          opposite: true,
          title: {
            text: 'Vehicle count 1'
          }
        }]
      }
    }
  },
  computed:{
    ...mapGetters({
      selectedDevice: 'qaModule/getSelectedQaDevice'
    })
  }
}
</script>

<style scoped>

</style>