import {
    convertPeriodToDateIsoStrings,
    dateToISOString,
    DeviceType,
    TaskType
} from "@/utils/utils";

export function getProjectSitePlanPayload(sitePlan, projectId) {

    let tasks = []
    if (sitePlan.installationDate) {

        tasks.push({
            taskType: TaskType.INSTALLATION,
            date: dateToISOString(sitePlan.installationDate),
            crew: sitePlan.installationCrew && sitePlan.installationCrew.length > 0 ? sitePlan.installationCrew.map(user => user.id) : [],
            //instruction: String,
            status: 'UNDONE',
            todos: sitePlan.device.todos
        })
    }

    if (sitePlan.decommissionDate) {
        tasks.push({
            taskType: TaskType.DECOMMISSION,
            date: dateToISOString(sitePlan.decommissionDate),
            crew: sitePlan.decommissionCrew && sitePlan.decommissionCrew.length > 0 ? sitePlan.decommissionCrew.map(user => user.id) : [],
            //instruction: String,
            status: 'UNDONE',
            todos: sitePlan.device.decommissionTodos
        })
    }

    let collectionPeriod = convertPeriodToDateIsoStrings(sitePlan.collectionPeriod)
    let processingPeriod = convertPeriodToDateIsoStrings(sitePlan.processingPeriod)

    let sitePayload = {
        projectId: projectId,
        siteName: sitePlan.editedSiteName && sitePlan.editedSiteName.length > 0 && sitePlan.editedSiteName !== sitePlan.name ? sitePlan.editedSiteName : sitePlan.name,
        siteLocationName: sitePlan.siteLocationName,
        longitude: sitePlan.siteLocation.coordinates[0],
        latitude: sitePlan.siteLocation.coordinates[1],
        status: "PLANNING",
        listOfPlanDevices: [{
            longitude: sitePlan.device.location.coordinates[0],
            latitude: sitePlan.device.location.coordinates[1],
            name: sitePlan.device.name,
            type: sitePlan.device.type,
            rotation: sitePlan.device.rotation,
            settingsProfile: sitePlan.device.settingsProfile,
            tasks: tasks,
            collectionPeriod: collectionPeriod,
            processingPeriod: processingPeriod,
        }]
    }

    return sitePayload
}

export function getUpdateSitePlanPayload(sitePlan) {

/*    let collectionPeriod = convertPeriodToDateIsoStrings(sitePlan.collectionPeriod)
    let processingPeriod = convertPeriodToDateIsoStrings(sitePlan.processingPeriod)*/

    let sitePayload = {
        id: sitePlan._id,
        siteName: sitePlan.siteName,
        siteLocationName: sitePlan.siteLocationName,
        longitude: sitePlan.siteLocation.coordinates[0],
        latitude: sitePlan.siteLocation.coordinates[1],
        status: sitePlan.status === 'PLANNED' ? 'PLANNING' : sitePlan.status,
        listOfPlanDevices: sitePlan.listOfPlanDevices
    }


    sitePayload.listOfPlanDevices.forEach(device => {
        if (device.tasks) {
            let installTask = device.tasks.find(task => task.taskType === TaskType.INSTALLATION)

            if (installTask) {
                installTask.date = dateToISOString(sitePlan.installationDate)
                installTask.crew = sitePlan.installationCrew && sitePlan.installationCrew.length > 0 ? sitePlan.installationCrew.map(user => user.id) : []
            } else if (sitePlan.installationDate) {
                //installation task not exist add new
                device.tasks.push({
                    taskType: TaskType.INSTALLATION,
                    date: dateToISOString(sitePlan.installationDate),
                    crew: sitePlan.installationCrew && sitePlan.installationCrew.length > 0 ? sitePlan.installationCrew.map(user => user.id) : [],
                    status: 'UNDONE',
                    todos: null
                })
            }

            let decommissionTask = device.tasks.find(task => task.taskType === TaskType.DECOMMISSION)
            if (decommissionTask) {
                decommissionTask.date = dateToISOString(sitePlan.decommissionDate)
                decommissionTask.crew = sitePlan.decommissionCrew && sitePlan.decommissionCrew.length > 0 ? sitePlan.decommissionCrew.map(user => user.id) : []
            } else if (sitePlan.decommissionDate) {
                //decommission task not exist add new
                device.tasks.push({
                    taskType: TaskType.DECOMMISSION,
                    date: dateToISOString(sitePlan.decommissionDate),
                    crew: sitePlan.decommissionCrew && sitePlan.decommissionCrew.length > 0 ? sitePlan.decommissionCrew.map(user => user.id) : [],
                    status: 'UNDONE',
                    todos: null
                })
            }
        }
    })

    return sitePayload
}

export function getAddDevicesToSitePlanPayload(plan, devices) {

    let payload = {
        id: plan._id,
        devicePlans: []
    }

    let installationDate;
    let decommissionDate;
    let installationCrew;
    let decommissionCrew;

    if (plan.listOfPlanDevices && plan.listOfPlanDevices.length > 0) {
        plan.listOfPlanDevices.forEach(device => {
            if (device.tasks && device.tasks.length > 0) {
                let installTask = device.tasks.find(task => task.taskType === TaskType.INSTALLATION)
                if (installTask && installTask.date) {
                    installationDate = installTask.date
                }
                if (installTask && installTask.crew) {
                    installationCrew = installTask.crew
                }

                let decommissionTask = device.tasks.find(task => task.taskType === TaskType.DECOMMISSION)
                if (decommissionTask && decommissionTask.date) {
                    decommissionDate = decommissionTask.date
                }
                if (decommissionTask && decommissionTask.crew) {
                    decommissionCrew = decommissionTask.crew
                }
            }
        })
    }

    devices.forEach(device => {

        console.log('Installdate: ', installationDate)
        let tasks = []
        if (installationDate) {
            tasks.push({
                taskType: TaskType.INSTALLATION,
                date: installationDate,
                crew: installationCrew && installationCrew.length > 0 ? installationCrew : [],
                instruction: device.instruction,
                status: 'UNDONE',
                todos: device.todos
            })
        }

        if (decommissionDate) {
            tasks.push({
                taskType: TaskType.DECOMMISSION,
                date: decommissionDate,
                crew: decommissionCrew && decommissionCrew.length > 0 ? decommissionCrew : [],
                instruction: device.decommissionInstruction,
                status: 'UNDONE',
                todos: device.decommissionTodos
            })
        }

        console.log('Collection: ', device.collectionPeriod)

        let newDevice = {
            longitude: device.location.coordinates[0],
            latitude: device.location.coordinates[1],
            name: device.name,
            type: device.type,
            rotation: device.rotation,
            settingsProfile: device.settingsProfile,
            tasks: tasks,
            collectionPeriod: convertPeriodToDateIsoStrings(device.collectionPeriod),
            processingPeriod: convertPeriodToDateIsoStrings(device.processingPeriod)
        }

        payload.devicePlans.push(newDevice)
    })

    return payload

}

export function getUpdateSitePlanDevicesPayload(devices) {
    let payload = {
        devicePlans: []
    }

    devices.forEach(device => {

        //Merge tasks
        device.tasks.forEach(task => {
            if (task.taskType === TaskType.INSTALLATION) {
                task.todos = device.todos
                task.instruction = device.instruction
            }
            if (task.taskType === TaskType.DECOMMISSION) {
                task.todos = device.decommissionTodos
                task.instruction = device.decommissionInstruction
            }
        })

        let newDevice = {
            _id: device._id,
            longitude: device.location.coordinates[0],
            latitude: device.location.coordinates[1],
            name: device.name,
            type: device.type,
            rotation: device.rotation,
            settingsProfile: device.settingsProfile,
            tasks: device.tasks,
            collectionPeriod:  convertPeriodToDateIsoStrings(device.collectionPeriod),
            processingPeriod: convertPeriodToDateIsoStrings(device.processingPeriod)
        }

        payload.devicePlans.push(newDevice)
    })

    return payload
}

export function getUpdateSitePlanDevicesMaintenancePayload(devices) {
    let payload = {
        devicePlans: []
    }

    devices.forEach(device => {

        //Merge maintenance tasks

        device.maintenanceTasks.forEach(task => {
            if (task._id) {
                console.log('Task with id')
                let oldTask = device.tasks.find(tsk => tsk._id === task._id)
                if (oldTask && oldTask.status !== 'DONE') {
                    oldTask.crew = task.crew && task.crew.length > 0 ? task.crew.map(user => user.id) : []
                    oldTask.instruction = task.instruction
                    oldTask.todos = task.todos
                    oldTask.date = dateToISOString(task.date)
                }

            } else {
                console.log('New task', task)
                if(task.date && task.crew){
                    device.tasks.push({
                        taskType: task.taskType,
                        date: dateToISOString(task.date),
                        crew: task.crew && task.crew.length > 0 ? task.crew.map(user => user.id) : [],
                        status: 'UNDONE',
                        todos: task.todos,
                        instruction: task.instruction
                    })
                }

            }
        })

        let newDevice = {
            _id: device._id,
            longitude: device.location.coordinates[0],
            latitude: device.location.coordinates[1],
            name: device.name,
            type: device.type,
            rotation: device.rotation,
            settingsProfile: device.settingsProfile,
            tasks: device.tasks,
            collectionPeriod:  device.collectionPeriod,
            processingPeriod: device.processingPeriod
        }

        payload.devicePlans.push(newDevice)
    })

    return payload
}

export function getNextDeviceNumber(projectSitePlans, newDevices, deviceType) {

    let deviceNumbers = []

    projectSitePlans.forEach(site => {
        let targetDevices = site.listOfPlanDevices.filter(device => device.type === deviceType)
        if (targetDevices.length > 0) {
            targetDevices.forEach(device => {
                if (device.name) {
                    let parts = device.name.split(' ')
                    if (parts.length > 0) {
                        deviceNumbers.push(parseInt(parts[1].trim()))
                    }
                }
            })
        }
    })

    let newTargetDevices = newDevices.filter(device => device.type === deviceType)
    if (newTargetDevices.length > 0) {
        newTargetDevices.forEach(device => {
            if (device.name) {
                let parts = device.name.split(' ')
                if (parts.length > 0) {
                    deviceNumbers.push(parseInt(parts[1].trim()))
                }
            }
        })
    }

    if (deviceNumbers.length > 0) {

        const max = Math.max(...deviceNumbers)
        const min = Math.min(...deviceNumbers)
        const missing = []

        for (let i = min; i <= max; i++) {
            if (!deviceNumbers.includes(i)) {
                missing.push(i)
            }
        }

        if (missing.length > 0) {
            return missing[0]
        }

        return Math.max(...deviceNumbers) + 1
    }

    return 1
}

export function getDeviceCountString(planDevices) {
    let cameras = 0
    let atc = 0
    let radars = 0
    planDevices.forEach(device => {
        if (device.type === DeviceType.CAMERA) {
            cameras++
        } else if (device.type === DeviceType.ATC) {
            atc++
        } else if (device.type === DeviceType.RADAR) {
            radars++
        }
    })

    if (cameras === 0 && atc === 0 && radars === 0) {
        return 'No devices'
    }

    let camStr = cameras > 0 ? cameras > 1 ? `${cameras} Cameras` : `${cameras} Camera` : ''
    let atcStr = atc > 0 ? atc > 1 ? `${atc} ATCs` : `${atc} ATC` : ''
    let radarStr = radars > 0 ? radars > 1 ? `${radars} Radars` : `${radars} Radar` : ''

    return `${camStr}${camStr !== '' && (atcStr !== '' || radarStr !== '') ? ', ' : ''}${atcStr}${atcStr !== '' && radarStr !== '' ? ', ' : ''}${radarStr}`
}

export function getUpdateProjectSitePlanStatusesPayload(plans) {
    let payload = {
        statuses: []
    }
    if (plans && plans.length > 0) {
        plans.forEach(plan => {
            if (plan.status !== 'DEPLOYED') {
                payload.statuses.push({
                    id: plan._id,
                    status: 'PLANNED'
                })
            }

        })
    }

    return payload
}