<template>
  <div class="ml-0 mr-0 mt-0">
    <apex-chart height="400" type="bar" :options="chartOptions" :series="series" ref="apexChart"></apex-chart>
  </div>
</template>

<script>
import {dateToISOString, getDateTime} from "@/utils/utils";
import {mapGetters} from "vuex";

export default {
  name: "pedestrian-chart",
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 400,
          fontFamily: 'Barlow'
        },
        plotOptions: {
          bar: {
            columnWidth: '40%',
          },
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: 'Pedestrians'
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          type: 'datetime',
          min: new Date('2024-09-06T00:00:00.000Z').getTime(),
          max: new Date('2024-09-08T00:00:00.000Z').getTime(),
          categories: [],
          labels: {
            datetimeUTC: false,
            /*            formatter: function (value) {
                          return new Date(value).
                        }*/
            /*            formatter: function(value, timestamp, opts) {
                          return timestamp
                        }*/
          }
        },
        yaxis: {
          title: {
            text: ''
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            format: "dd-MM-yyyy HH:mm:ss"
          },
          y: {
            formatter: function (val) {
              return  val
            }
          }
        }
      },
    }
  },
  mounted() {
    console.log('Today: ', new Date().toISOString())
    console.log('Selected device: ',this.selectedDevice)
    this.setChartData()
  },
  methods:{
    setChartData(){

      let series = []
      let categories = []

      if(this.selectedDevice && this.selectedDevice.chartsData && this.selectedDevice.chartsData.pedVolumeData){
        let pedVolumeData = this.selectedDevice.chartsData.pedVolumeData
        if(pedVolumeData.length > 0){
          let arrays = pedVolumeData.map(volData => volData.x)
          console.log('Arrays: ', arrays)

          categories = [...new Set(...arrays)];
          console.log('Unique: ', categories)

          pedVolumeData.forEach(volData => {
            let ser = {
              name: volData.name,
              data:[]
            }

            categories.forEach(date => {
              let index = volData.x.indexOf(date)
              if(index !== -1){
                ser.data.push(volData.y[index])
              }else{
                ser.data.push(0)
              }
            })

            series.push(ser)

          })
        }
      }

      this.series = series

      this.chartOptions = {
        chart: {
          type: 'bar',
          height: 400,
          fontFamily: 'Barlow'
        },
        plotOptions: {
          bar: {
            columnWidth: '60%',
          },
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: 'Pedestrians'
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          type: 'datetime',
          categories: categories,
          labels: {
            datetimeUTC: false,
          }
        },
        yaxis: {
          title: {
            text: ''
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            format: "dd-MM-yyyy HH:mm:ss"
          },
          y: {
            formatter: function (val) {
              return  val
            }
          }
        }
      }

      /*     this.series = [{
             name: 'Bus',
             data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 61, 58, 63, 60, 66]
           }, {
             name: 'Light Vehicle',
             data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 87, 105, 91, 114, 94]
           }, {
             name: 'Small heavy vehicle',
             data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 5, 48, 52, 53, 41]
           }]*/

      /*     this.chartOptions = {
             chart: {
               type: 'bar',
                   height: 500,
                   fontFamily: 'Barlow'
             },
             plotOptions: {
               bar: {
                 columnWidth: '40%',
               },
             },
             dataLabels: {
               enabled: false
             },
             title: {
               text: 'Vehicles Distribution'
             },
             stroke: {
               show: true,
                   width: 2,
                   colors: ['transparent']
             },
             xaxis: {
               type: 'datetime',
                   min: new Date('2024-09-06T00:00:00.000Z').getTime(),
                   max: new Date('2024-09-08T00:00:00.000Z').getTime(),
                   categories: [
                 '2024-09-06T07:45:00.000Z', '2024-09-06T08:00:00.000Z', '2024-09-06T08:15:00.000Z', '2024-09-06T08:30:00.000Z', '2024-09-06T08:45:00.000Z',
                 '2024-09-06T09:00:00.000Z', '2024-09-06T09:15:00.000Z', '2024-09-06T14:45:00.000Z', '2024-09-06T15:00:00.000Z', '2024-09-06T15:15:00.000Z',
                 '2024-09-06T15:30:00.000Z', '2024-09-06T15:45:00.000Z', '2024-09-06T16:00:00.000Z', '2024-09-06T16:15:00.000Z', '2024-09-06T16:15:00.000Z'
               ],
                   labels: {
                 datetimeUTC: false,
                 /!*            formatter: function (value) {
                               return new Date(value).
                             }*!/
                 /!*            formatter: function(value, timestamp, opts) {
                               return timestamp
                             }*!/
               }
             },
             yaxis: {
               title: {
                 text: ''
               }
             },
             fill: {
               opacity: 1
             },
             tooltip: {
               x: {
                 format: "dd-MM-yyyy HH:mm:ss"
               },
               y: {
                 formatter: function (val) {
                   return  val
                 }
               }
             }
           }*/


    }
  },
  computed:{
    ...mapGetters({
      selectedDevice: 'qaModule/getSelectedQaDevice'
    }),
  }
}
</script>

<style scoped>

</style>