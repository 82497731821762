<template>
  <div id="map-container" class="columns is-gapless qa-map" style="margin-bottom: 0">
    <gmap-map
        ref="googleMap"
        :center="mapCenter"
        @click="onMapClicked"
        :zoom="9"
        :heading="heading"
        :options="options"
        map-type-id="terrain"
        style="width: 100%"
        class="mapbox-container">

      <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                        @closeclick="onCloseInfoWindow">
      </gmap-info-window>

      <my-google-marker v-for="(item, index) in markers" :key="JSON.stringify(item)"
                        :item="item"
                        @onMarkerClicked="onMarkerClicked(item, index)"
                        :is-selected="selectedDevice === item"
                        :draw-mode="null">
      </my-google-marker>
      <div slot="visible">
        <qa-legend v-if="selectedProject && selectedDevice === null" @onQaFilterChanged="qaFilterChanged"></qa-legend>
      </div>
    </gmap-map>
  </div>
</template>

<script>
import MyGoogleMarker from "@/modules/projects-module/components/my-google-marker/my-google-marker.vue";
import {
  getDateTime,
  getGmapPopupContent,
  getGmapPopupDeviceContent,
  getGmapPopupQaContent,
  gMapStyles
} from "@/utils/utils";
import {bbox, lineString} from "@turf/turf";
import {mapActions, mapGetters} from "vuex";
import QaLegend from "@/modules/qa-module/components/qa-legend/qa-legend.vue";

export default {
  name: "g-map-qa",
  components: {QaLegend, MyGoogleMarker},
  data() {
    return {
      mapCenter: {lat: -37.80193, lng: 144.94444},
      mapData: [],
      mapZoom: 10,
      drawMode: null,
      heading: 0,
      options: {styles: gMapStyles},
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: '',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -16
        }
      },
      filter: {site: true, device: true, sitePlan: true, planDevice: true}
    }
  },
  async mounted() {
    console.log('Gmap input sites: ', this.sitePlans)
    await this.$gmapApiPromiseLazy();

    console.log('Map: ', this.$refs.googleMap)


    this.$refs.googleMap.$mapObject.addListener('projection_changed', () => {
      console.log('Map heading', this.$refs.googleMap.$mapObject.heading)
      this.heading = this.$refs.googleMap.$mapObject.heading
    });
    if (this.selectedDevice) {
      console.log('Markers: ', this.markers)
      let index = this.markers.findIndex(marker => marker._id === this.selectedDevice._id)
      if (index !== -1) {
        this.zoomMapToSelectedDevice(true)
        this.toggleInfoWindow(this.markers[index], index)
      } else {
        if (!this.infoWinOpen) {
          this.zoomMapToSelectedDevice(true)
        }
        this.infoWinOpen = false
      }
    } else {
      this.zoomMapToSelectedDevice(true)
    }

  },
  methods: {
    ...mapActions({
      setSelectedQaDevice: 'qaModule/setSelectedQaDevice'
    }),
    qaFilterChanged(selection) {
      console.log('Legend changed: ', selection)
      //this.setLegendSelection(selection)
    },
    onCloseInfoWindow() {
      this.infoWinOpen = false
      this.setSelectedQaDevice(null)
      //this.zoomMapToSelectedSiteOrProject(true)
    },
    onMapClicked() {
      console.log('On map clicked')
      this.$emit('onMapClicked')
      this.setSelectedQaDevice(null)
      if (!this.infoWinOpen) {
        this.zoomMapToSelectedDevice(true)
      }
      this.infoWinOpen = false
    },
    onMarkerClicked(device, index) {
      console.log('On marker clicked', device)
      if (this.selectedDevice === device) {
        this.setSelectedQaDevice(null)
      } else {
        setTimeout(() => {
          this.setSelectedQaDevice(device)
        }, 500)
      }


      this.toggleInfoWindow(device, index)
      this.zoomMapToSelectedDevice(true, device)
    },
    toggleInfoWindow(device, idx) {
      this.infoWindowPos = {lat: device.location.coordinates[1], lng: device.location.coordinates[0]};
      this.setInfoWindowContent(device)
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    setInfoWindowContent(device) {
      if (device.objectType === 'device') {
        console.log('Toggle device: ', device)
        let additionalData = {
          userName: this.userFullName(device),
          createdAt: this.atDate(device),
          status: this.status(device),
          statusColor: this.statusColor(device),
          dataVersion: this.dataVersion(device)
        }
        console.log('Additional data: ', additionalData)
        this.infoOptions.content = getGmapPopupQaContent(device, this.deviceStatusColor(device.currentStatus), this.selectedProject.project.name, additionalData)
      }
    },
    zoomMapToSelectedDevice(animate, device) {
      console.log('GMap, zoom to projects or sites')
      if (this.selectedProject != null) {
        //Zoom to selected site
        let coordinates = []
        if (device) {
          coordinates.push(device.location.coordinates)
        } else if (this.markers && this.markers.length > 0) {
          this.markers.forEach(device => {
            if (device.location) {
              coordinates.push(device.location.coordinates)
            }
          })
        }

        if (coordinates.length > 0) {
          console.log('COORDINATES', coordinates)

          if (coordinates.length > 1) {
            let line = lineString(coordinates);
            let bb = bbox(line);
            console.log('Bounding box:', bb)
            let boundsGMap = new window.google.maps.LatLngBounds()
            boundsGMap.extend({lat: bb[1], lng: bb[0]})
            boundsGMap.extend({lat: bb[3], lng: bb[2]})
            console.log('LatLngBounds: ', boundsGMap)
            this.$refs.googleMap.$mapObject.panTo(boundsGMap.getCenter());
            this.$refs.googleMap.$mapObject.fitBounds(boundsGMap, 100)
          } else {
            this.$refs.googleMap.$mapObject.panTo({lat: coordinates[0][1], lng: coordinates[0][0]});
          }
        } else if (animate) {
          this.$refs.googleMap.$mapObject.panTo(this.mapCenter);
        }
      } else {
        this.$refs.googleMap.$mapObject.panTo(this.mapCenter);
      }
    },
    deviceStatusColor(name) {
      if (this.$store.getters.getSettings) {
        let status = this.$store.getters.getSettings.deviceStatuses.find(status => status.name === name)
        if (status)
          return status.color
      }

      return '#ffffff'
    },
    onFilterChanged(data) {
      this.filter = data
      console.log('On filter changed: ', this.filter)
    },
    status(device) {
      if (device && device.qaValidation) {
        if (device.qaValidation.qaStatus === 'PASS') {
          return 'Pass'
        } else if (device.qaValidation.qaStatus === 'FAIL') {
          return 'Fail'
        } else if (device.qaValidation.qaStatus === 'PASS_WITH_CHANGES') {
          return 'Pass With Changes'
        }
      }

      return '-'
    },
    statusColor(device) {
      if (device && device.qaValidation) {
        if (device.qaValidation.qaStatus === 'PASS') {
          return '#48c78e'
        } else if (device.qaValidation.qaStatus === 'FAIL') {
          return '#f14668'
        } else if (device.qaValidation.qaStatus === 'PASS_WITH_CHANGES') {
          return '#3e8ed0'
        }
      }
      return 'black'
    },
    userFullName(device) {
      if (device && device.qaValidation) {
        return this.getUser(device.qaValidation.createdBy).fullName
      }

      return '-'
    },
    atDate(device) {
      if (device && device.qaValidation && device.qaValidation.createdAt) {
        return getDateTime(new Date(device.qaValidation.createdAt))
      }

      return '-'
    },
    dataVersion(device) {
      if (device && device.qaValidation) {
        return device.qaValidation.dataVersion
      }

      return '-'
    },
  },
  computed: {
    ...mapGetters({
      selectedProject: 'qaModule/getSelectedQaProject',
      selectedDevice: 'qaModule/getSelectedQaDevice',
      getUser: 'getUserById',
      qaLegendSelection: 'qaModule/qaLegendSelection',
    }),
    mapHeight() {
      if (this.selectedProject == null) {
        return 'full-height-map'
      } else {
        return 'smaller-height-map'
      }
    },
    devices() {
      if (this.selectedProject) {
        return this.selectedProject.devices
      }
      return []
    },
    markers() {
      let devices = []
      if (this.devices && this.devices.length > 0) {
        this.devices.forEach(device => {
          device.objectType = 'device'
          device.color = this.statusColor(device)
          if (device.location) {
            if (device.qaValidation === null && this.qaLegendSelection['NO_QA'].value) {
              devices.push(device)
            } else if (this.qaLegendSelection[device.qaValidation.qaStatus].value) {
              devices.push(device)
            }
          }
        })
      }
      return devices
    }
  },
  watch: {
    qaLegendSelection: {
      handler: function (newVal, oldVal) {
        this.zoomMapToSelectedDevice(true)
      },
      deep: true
    },
    selectedProject: {
      handler: function (newVal, oldVal) {
        console.log('Selected project changed:', this.selectedProject)
        if ((oldVal && newVal === null) || (oldVal === null && newVal)) {
          this.zoomMapToSelectedDevice(true)
        }
      },
      deep: true
    },
    selectedDevice: {
      handler: function (newVal, oldVal) {
        if (this.selectedDevice === null) {
          if (!this.infoWinOpen) {
            this.zoomMapToSelectedDevice(true)
          }
          this.infoWinOpen = false
        } else {
          if (this.infoWinOpen) {
            this.setInfoWindowContent(this.selectedDevice)
          }
        }
      },
      deep: true
    },
  }
}
</script>

<style scoped lang="scss">
.qa-map {
  height: 480px;
  transition: all .6s linear;
}

</style>