<template>
  <div>
    <b-loading v-model="isLoading" :can-cancel="true" :is-full-page="true"></b-loading>
    <div class="m-4 card p-4">
      <b-field class="is-flex-grow-1 mr-1 mt-1 mb-4">
        <b-select placeholder="Select date" size="is-small" @input="onPeriodSelected" v-model="selectedPeriod">
          <option
              v-for="option in selectOptions"
              :value="option"
              :key="option.name">
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
      <apex-chart type="line" height="400" :options="chartOptions" :series="series" @dataPointMouseEnter="selectLine"
                  @dataPointMouseLeave="deselectLine"></apex-chart>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {getDateTime} from "@/utils/utils";

export default {
  name: "health-chart",
  data() {
    return {
      isLoading: false,
      timer: null,
      series: [],
      selectOptions: [{name: 'Last Day', value: 1}, {name: 'Last 7 days', value: 7}, {name: 'Last 14 days', value: 14}, {name: 'Last 30 days', value: 30}],
      selectedPeriod: {name: 'Last Day', value: 1},
      chartOptions: {
        chart: {
          height: 400,
          type: 'line',
          fontFamily: 'Barlow'
        },
        markers: {
          size: 1,
          strokeWidth: 1,
        },
        stroke: {
          width: 4,
          curve: 'straight'
        },
        title: {
          text: 'Device Health',
          align: 'left'
        },
        fill: {
          type: 'solid',
          color: 'transparent'
        },
        yaxis: {
          min: 11,
          max: 15,
          labels: {
            formatter: function (val) {
              return val.toFixed(2) + 'V';
            },
          },
          title: {
            text: 'Voltage'
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC: false
          }
        },
        tooltip: {
          intersect: true,
          shared: false,
          x: {
            formatter: function (val) {
              return getDateTime(val)
            }
          },
          y: {
            formatter: function (val) {
              return val.toFixed(2) + 'V'
            }
          }
        },
        /*        tooltip: {
                  custom: function({series, seriesIndex, dataPointIndex, w}) {
                    console.log('Series: ', seriesIndex)
                    return ''
                  },
                },*/
      },
    }
  },
  async created() {
    this.isLoading = true
    await this.getLiveMonitoring(this.selectedPeriod.value)
    this.isLoading = false
  },
  mounted() {
    this.timer = setInterval(async () => {
      await this.getLiveMonitoring(this.selectedPeriod.value)
    }, 10*60*1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    ...mapActions({
      getLiveMonitoring: 'liveMonitoringModule/getLiveMonitoring'
    }),
    async onPeriodSelected(selection) {
      console.log('On period selected', selection)
      this.selectedPeriod = selection
      this.isLoading = true
      await this.getLiveMonitoring(selection.value)
      this.isLoading = false

    },
    selectLine(event, chartContext, config) {
      console.log('Clicked:', config)
      if (config.seriesIndex >= 0) {
        console.log('Selected serie: ', this.series[config.seriesIndex])
        this.$emit('deviceHover', this.series[config.seriesIndex].deviceId)
      } else {
        this.$emit('deviceHover', null)
      }
    },
    deselectLine(event, chartContext, config) {
      this.$emit('deviceHover', null)
    },
    setData() {
      if (this.liveMonitoring && this.liveMonitoring.length > 0) {
        let series = []
        this.series = []

        this.liveMonitoring.forEach(data => {
          if (this.selectedProjects.length === 0 || this.selectedProjects.map(project => project._id).includes(data.project._id)) {
            if(data.device && data.device.health) {
              if (this.legendSelection[data.device.health.status]) {
                let serie = {
                  deviceId: data.device._id,
                  name: data.device.name,
                  data: []
                }
                if (data.healthData && data.healthData.length > 0) {
                  data.healthData.forEach(health => {
                    serie.data.push([health.createdAt, health.voltage])
                  })
                }

                series.push(serie)
              }
            }
          }
        })
        console.log('Created series: ', this.series)
        this.series = series
      } else {
        this.series = []
      }
    }
  },
  computed: {
    ...mapGetters({
      liveMonitoring: 'liveMonitoringModule/liveMonitoring',
      selectedProjects: 'liveMonitoringModule/selectedProjects',
      legendSelection: 'liveMonitoringModule/legendSelection',
    })
  },
  watch: {
    liveMonitoring() {
      console.log('Live monitoring changed: ', this.liveMonitoring)
      this.setData()
    },
    selectedProjects() {
      this.setData()
    },
    legendSelection() {
      this.setData()
    }
  }
}
</script>

<style scoped>

</style>