<template>
  <div>
    <div class="columns ml-0 mr-0" v-if="showCopyActions">
      <div class="is-size-6 has-text-grey-light has-text-weight-normal mb-2">COMMON COLLECTION/PROCESSING PERIODS</div>
      <div class="column ml-auto m-0 p-0 is-flex mr-1" style="max-width: 64px">
        <b-tooltip label="Copy to selected" class="ml-auto mr-1" :delay="1000" type="is-dark">
          <b-button @click="onApplyToSelection" size="is-small" type="is-primary" outlined
                    icon-right="check-all"></b-button>
        </b-tooltip>
        <b-tooltip label="Copy to all" :delay="1000" type="is-dark">
          <b-button @click="onApplyToAll" size="is-small" type="is-primary" outlined
                    icon-right="content-copy"></b-button>
        </b-tooltip>
      </div>
    </div>
    <div class="p-0 pl-0 m-0">
      <div class="columns m-0 p-0">
        <div class="column m-0 p-0">
<!--          <div class="is-size-7 mt-0 ml-2 mb-2 has-text-weight-semibold">Collection period</div>-->
          <div class="columns ml-0 mr-2 mb-0">
            <div class="column ml-0 is-3 pl-0">
              <b-field grouped label="Data collecting range *" label-position="on-border">
                <b-datepicker
                    expanded
                    rounded
                    @input="onCollectionDateRangeChanged"
                    size="is-small"
                    placeholder="Data collecting range *"
                    v-model="collectionPeriod.dateRange"
                    range>
                  <template v-slot:trigger>
                    <b-input expanded size="is-small" readonly rounded placeholder="Data collecting range *"
                             :value="dataCollectionRangeString"
                             type="text"
                             icon-right="close-circle"
                             icon-right-clickable
                             @icon-right-click="clearIconClick(collectionPeriod)"
                             required/>
                  </template>
                </b-datepicker>
              </b-field>
            </div>
            <div class="column m-0 p-0 pt-3 is-flex ml-3" style="max-width: 300px"
                 v-for="(timeRange, index) in collectionPeriod.timeRanges"
                 :key="timeRange.startTime + timeRange.endTime">
              <div class="is-flex">
                <b-field label="Start time" label-position="on-border">
                  <b-timepicker
                      rounded
                      placeholder="Start time..."
                      icon="clock"
                      size="is-small"
                      @close="onCloseTimePicker"
                      v-model="timeRange.startTime"
                      :enable-seconds="false">
                  </b-timepicker>
                </b-field>
                <b-field label="End time" label-position="on-border" class="ml-1">
                  <b-timepicker
                      rounded
                      placeholder="End time..."
                      icon="clock"
                      @close="onCloseTimePicker"
                      size="is-small"
                      v-model="timeRange.endTime"
                      :enable-seconds="false">
                  </b-timepicker>
                </b-field>
                <b-tooltip label="Remove collection time" :delay="1000" type="is-dark" class="ml-1"
                           style="margin-top: 3px">
                  <b-icon icon="close-circle" size="is-small" type="is-dark" style="cursor: pointer"
                          @click.native="clearIconTimeRangeClick(collectionPeriod, index)"></b-icon>
                </b-tooltip>
              </div>
            </div>
            <div class="column" style="max-width: 92px">
              <b-tooltip label="Add collection time" class="ml-auto mr-1" :delay="1000" type="is-dark">
                <b-button icon-left="plus" @click="addCollectionTime" size="is-small"></b-button>
              </b-tooltip>
            </div>
          </div>
<!--          <div class="is-size-7 mt-0 ml-2 mb-2 has-text-weight-semibold">Processing period</div>-->
          <div class="columns ml-0 mr-2">
            <div class="column is-3 pl-0">
              <b-field grouped label="Data processing range *" label-position="on-border">
                <b-datepicker
                    expanded
                    rounded
                    @input="onProcessingDateRangeChanged"
                    size="is-small"
                    placeholder="Data processing range *"
                    v-model="processingPeriod.dateRange"
                    :disabled="collectionPeriod.dateRange === null || collectionPeriod.dateRange.length === 0"
                    :min-date="collectionPeriod.dateRange[0]"
                    :max-date="collectionPeriod.dateRange[1]"
                    range>
                  <template v-slot:trigger>
                    <b-input size="is-small" expanded readonly rounded placeholder="Data processing range *"
                             :value="dataProcessingRangeString"
                             type="text"
                             icon-right="close-circle"
                             icon-right-clickable
                             @icon-right-click="clearIconClick(processingPeriod)"
                             required/>
                  </template>
                </b-datepicker>
              </b-field>
            </div>
            <div class="column m-0 p-0 pt-3 is-flex ml-3" style="max-width: 300px"
                 v-for="(timeRange, index) in processingPeriod.timeRanges"
                 :key="timeRange.startTime + timeRange.endTime">
              <div class="is-flex">
                <b-field label="Start time" label-position="on-border">
                  <b-timepicker
                      rounded
                      placeholder="Start time..."
                      icon="clock"
                      @close="onCloseTimePicker"
                      size="is-small"
                      v-model="timeRange.startTime"
                      :enable-seconds="false">
                  </b-timepicker>
                </b-field>
                <b-field label="End time" label-position="on-border" class="ml-1">
                  <b-timepicker
                      rounded
                      placeholder="End time..."
                      @close="onCloseTimePicker"
                      icon="clock"
                      size="is-small"
                      v-model="timeRange.endTime"
                      :enable-seconds="false">
                  </b-timepicker>
                </b-field>
                <b-tooltip label="Remove processing time" :delay="1000" type="is-dark" class="ml-1"
                           style="margin-top: 3px">
                  <b-icon icon="close-circle" size="is-small" type="is-dark" style="cursor: pointer"
                          @click.native="clearIconTimeRangeClick(processingPeriod, index)"></b-icon>
                </b-tooltip>
              </div>
            </div>
            <div class="column" style="max-width: 92px">
              <b-tooltip label="Add processing time" class="ml-auto mr-1" :delay="1000" type="is-dark">
                <b-button icon-left="plus" @click="addProcessingTime" size="is-small"></b-button>
              </b-tooltip>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getDateRange, getInputPeriod} from "@/utils/utils";

export default {
  name: "collection-processing-input",
  props:{
    showCopyActions: {
      type: Boolean,
      default: true,
      required: false
    },
    initialCollectionPeriod:{
      type: Object
    },
    initialProcessingPeriod:{
      type: Object
    }
  },
  data() {
    return {
      collectionPeriod: {dateRange: [], timeRanges: [{startTime: null, endTime: null}]},
      processingPeriod: {dateRange: [], timeRanges: [{startTime: null, endTime: null}]}
    }
  },
  mounted() {
    console.log('Periods:', this.initialProcessingPeriod)
    if(this.initialProcessingPeriod){
      this.processingPeriod = getInputPeriod(this.initialProcessingPeriod)
    }

    if(this.initialCollectionPeriod){
      this.collectionPeriod = getInputPeriod(this.initialCollectionPeriod)
    }
  },
  methods: {
    onApplyToSelection() {
      console.log('On Apply to selection')
      this.$emit('onCopyToSelection', {
        collectionPeriod: this.collectionPeriod,
        processingPeriod: this.processingPeriod
      })
    },
    onApplyToAll() {
      console.log('On apply to all')
      this.$emit('onCopyToAll', {collectionPeriod: this.collectionPeriod, processingPeriod: this.processingPeriod})
    },
    onCollectionDateRangeChanged(event) {
      this.collectionPeriod.dateRange = event
    },
    onProcessingDateRangeChanged(event) {
      this.processingPeriod.dateRange = event
    },
    onCloseTimePicker() {
      console.log('Collection times: ', this.collectionPeriod)
      console.log('Processing times: ', this.processingPeriod)
    },
    addCollectionTime() {
      console.log('Time is: ', this.collectionPeriod.timeRanges)
      console.log('Dates is: ', this.collectionPeriod.dateRange)
      this.collectionPeriod.timeRanges.push({startTime: null, endTime: null})
    },
    addProcessingTime() {
      console.log('Time is: ', this.processingPeriod.timeRanges)
      console.log('Dates is: ', this.processingPeriod.dateRange)
      this.processingPeriod.timeRanges.push({startTime: null, endTime: null})
    },
    clearIconClick(field) {
      console.log('Clear icon click')
      if (field && field.dateRange) {
        field.dateRange = null
      }
    },
    clearIconTimeRangeClick(field, index) {
      console.log('Clear icon time range: ', field, index)
      if (field && field.timeRanges[index]) {
        field.timeRanges.splice(index, 1)
      }
    },
  },
  computed: {
    dataCollectionRangeString() {
      return this.collectionPeriod.dateRange && this.collectionPeriod.dateRange.length > 0 ? getDateRange(this.collectionPeriod.dateRange) : ''
    },
    dataProcessingRangeString() {
      return this.processingPeriod.dateRange && this.processingPeriod.dateRange.length > 0 ? getDateRange(this.processingPeriod.dateRange) : ''
    },
  },
  watch: {
    collectionPeriod:{
      deep: true,
      handler(val){
        console.log('Collection Period changed', val)
        this.$emit('collectionPeriodChanged', val)
      }
    },
    processingPeriod:{
      deep: true,
      handler(val){
        console.log('Processing Period changed', val)
        this.$emit('processingPeriodChanged', val)
      }
    },
    initialCollectionPeriod:{
      deep: true,
      handler(val){
        console.log('Processing Period changed', val)
          this.collectionPeriod = val
      }
    },
    initialProcessingPeriod: {
      deep: true,
      handler(val){
        console.log('Processing Period changed', val)
          this.processingPeriod = val
      }
    }
  }
}
</script>

<style scoped>

</style>