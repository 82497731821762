<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="90%" scroll="keep" style="z-index: 101" @close="clearFields()">
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head">
          <h6 class="is-size-5 ml-auto mr-auto pl-4 has-text-weight-semibold">Add Site Plan Devices</h6>
          <button
              type="button"
              class="delete"
              @click="show(false)"/>
        </header>
        <section class="modal-card-body p-0 pt-4 pl-2 pr-2">
          <div class="columns m-0 mb-2">
            <div class="column">
              <h4 class="is-size-4">{{ site.siteName }}</h4>
            </div>
            <div class="column is-flex">
              <b-field class="ml-auto">
                <b-select placeholder="Select device type" rounded v-model="deviceType">
                  <option :value="DeviceType.CAMERA">Camera - TMC/Live/Parking</option>
                  <option :value="DeviceType.RADAR">Radar</option>
                  <option :value="DeviceType.ATC">ATC</option>
                </b-select>
              </b-field>
            </div>
          </div>
          <map-input v-if="mapType === 'mapbox'"
                     @onMapInputClicked="onMapClicked"
                     @onCoordinatesUpdated="onCoordinatesUpdated"
                     @onRotationChanged="onRotationChanged"
                     draw-controls-mode="full"
                     :selected-project="selectedProject"
                     :devices="devices"></map-input>
          <g-map-input v-if="mapType === 'gmap'"
                       @onMapInputClicked="onMapClicked"
                       @onCoordinatesUpdated="onCoordinatesUpdated"
                       @onRotationChanged="onRotationChanged"
                       :is-markers-selectable="false"
                       :selected-project="selectedProject"
                       draw-controls-mode="full"
                       :site-location="site.siteLocation"
                       :site-name="site.siteName"
                       :devices="devices"></g-map-input>
          <div class="ml-4 mr-4 mb-6">
            <div class="is-size-6 has-text-grey-light has-text-weight-normal mt-6 mb-2">NEW DEVICES</div>
            <add-device-card v-for="(device, index) in devices" :device="device" :key="index"
                             @onDeviceDeleteClicked="removeDevice(index)"
                             @onDeviceLocationChanged="onDeviceLocationChanged"
                             @onDeviceInstructionChanged="onDeviceInstructionChanged"
                             @onDeviceDecommissionInstructionChanged="onDeviceDecommissionInstructionChanged"
                             @onDeviceCollectionPeriodChanged="onDeviceCollectionPeriodChanged"
                             @onDeviceProcessingPeriodChanged="onDeviceProcessingPeriodChanged"
                             class="mb-2"></add-device-card>
            <configuration-input class="mt-6 mb-2" ref="cameraConfig" v-if="hasCameraDevice"
                                 title="COMMON CONFIGURATION"
                                 :device-type="DeviceType.CAMERA" :key="DeviceType.CAMERA"></configuration-input>
            <todo-list-input class="mt-6 mb-2" title="COMMON TODO LIST" ref="cameraTodo"
                             v-if="hasCameraDevice" :device-type="DeviceType.CAMERA"
                             :key="DeviceType.CAMERA + 'todo'"></todo-list-input>
            <configuration-input class="mt-6 mb-2" ref="atcConfig" v-if="hasAtcDevice"
                                 :device-type="DeviceType.ATC" :key="DeviceType.ATC"
                                 title="COMMON CONFIGURATION"></configuration-input>
            <todo-list-input class="mt-6 mb-2" title="COMMON TODO LIST" ref="atcTodo" v-if="hasAtcDevice"
                             :device-type="DeviceType.ATC" :key="DeviceType.ATC + 'todo'"></todo-list-input>
            <configuration-input class="mt-6 mb-2" ref="radarConfig" v-if="hasRadarDevice"
                                 :device-type="DeviceType.RADAR" :key="DeviceType.RADAR"
                                 title="COMMON CONFIGURATION"></configuration-input>
            <todo-list-input class="mt-6 mb-2" title="COMMON TODO LIST" ref="radarTodo" v-if="hasRadarDevice"
                             :device-type="DeviceType.RADAR" :key="DeviceType.RADAR + 'todo'"></todo-list-input>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button class="is-primary is-fullwidth m-2" @click="onCreateDevices" rounded>CREATE DEVICES</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MapInput from "@/components/map-input/map-input.vue";
import ConfigurationInput from "@/modules/projects-module/components/configuraton-input/configuration-input.vue";
import {mapActions, mapGetters} from "vuex";
import {
  capitalizeFirstLetter,
  DeviceStatus,
  DeviceType,
  extractArrayGpsCoordinates,
  extractGMapGpsCoordinates
} from "@/utils/utils";
import GMapInput from "@/components/g-map-input/g-map-input.vue";
import AddDeviceCard from "@/modules/projects-module/components/add-device-card/add-device-card.vue";
import {getAddDevicesToSitePlanPayload} from "@/utils/helper";
import TodoListInput from "@/modules/projects-module/components/todo-list-input/todo-list-input.vue";

export default {
  name: "add-site-devices",
  components: {TodoListInput, AddDeviceCard, MapInput, GMapInput, ConfigurationInput},
  props: {
    site: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      modalActive: false,
      devices: [],
      deviceType: 'CAMERA'
    }
  },
  methods: {
    ...mapActions({
      getLocation: 'getLocationData',
      addDevicesToSitePlan: 'projectsModule/addDevicesToSitePlan'
    }),
    show(show) {
      this.modalActive = show
      if (!this.modalActive) {
        this.clearFields()
      }

      console.log('Add devices for site plan: ', this.site)
    },
    onCreateDevices() {
      let cameraConf = null
      let atcConf = null
      let radarConf = null

      let cameraTodo = []
      let atcTodo = []
      let radarTodo = []

      let cameraDecommissionTodo = []
      let atcDecommissionTodo = []
      let radarDecommissionTodo = []

      if (this.$refs.cameraConfig && this.$refs.cameraConfig.configuration) {
        cameraConf = {}
        this.$refs.cameraConfig.configuration.forEach((config) => {
          cameraConf[config.field] = config.value
        })
      }

      if (this.$refs.atcConfig && this.$refs.atcConfig.configuration) {
        atcConf = {}
        this.$refs.atcConfig.configuration.forEach((config) => {
          atcConf[config.field] = config.value
        })
      }

      if (this.$refs.radarConfig && this.$refs.radarConfig.configuration) {
        radarConf = {}
        this.$refs.radarConfig.configuration.forEach((config) => {
          radarConf[config.field] = config.value
        })
      }

      if (this.$refs.cameraTodo && this.$refs.cameraTodo.installConfiguration) {
        this.$refs.cameraTodo.installConfiguration.forEach((config) => {
          cameraTodo.push({
            name: config.name,
            description: config.description
          })
        })
      }

      if (this.$refs.atcTodo && this.$refs.atcTodo.installConfiguration) {
        this.$refs.atcTodo.installConfiguration.forEach((config) => {
          atcTodo.push({
            name: config.name,
            description: config.description
          })
        })
      }

      if (this.$refs.radarTodo && this.$refs.radarTodo.installConfiguration) {
        this.$refs.radarTodo.installConfiguration.forEach((config) => {
          radarTodo.push({
            name: config.name,
            description: config.description
          })
        })
      }

      if (this.$refs.cameraTodo && this.$refs.cameraTodo.decommissionConfiguration) {
        this.$refs.cameraTodo.decommissionConfiguration.forEach((config) => {
          cameraDecommissionTodo.push({
            name: config.name,
            description: config.description
          })
        })
      }

      if (this.$refs.atcTodo && this.$refs.atcTodo.decommissionConfiguration) {
        this.$refs.atcTodo.decommissionConfiguration.forEach((config) => {
          atcDecommissionTodo.push({
            name: config.name,
            description: config.description
          })
        })
      }

      if (this.$refs.radarTodo && this.$refs.radarTodo.decommissionConfiguration) {
        this.$refs.radarTodo.decommissionConfiguration.forEach((config) => {
          radarDecommissionTodo.push({
            name: config.name,
            description: config.description
          })
        })
      }


      this.devices.forEach(device => {
        if (device.type === DeviceType.CAMERA) {
          if (cameraConf) {
            device.settingsProfile = cameraConf
          }
          device.todos = cameraTodo
          device.decommissionTodos = cameraDecommissionTodo

        } else if (device.type === DeviceType.ATC) {
          if (atcConf) {
            device.settingsProfile = atcConf
          }
          device.todos = atcTodo
          device.decommissionTodos = atcDecommissionTodo
        } else if (device.type === DeviceType.RADAR) {
          if (radarConf) {
            device.settingsProfile = radarConf
          }
          device.todos = radarTodo
          device.decommissionTodos = radarDecommissionTodo
        }
      })
      this.addNewDevices()
    },
    async addNewDevices() {

      console.log('Devices: ', this.devices)
      let payload = getAddDevicesToSitePlanPayload(this.site, this.devices)
      console.log('Payload: ', payload)

      this.isLoading = true
      let response = await this.addDevicesToSitePlan(payload)
      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        this.clearFields()
        this.showToast('Devices successfully added', 'is-success')
        this.show(false)
      }
      this.isLoading = false
    },
    clearFields() {
      this.devices = []
      this.deviceType = 'CAMERA'
    },
    removeDevice(index) {
      this.devices.splice(index, 1)
    },
    async onMapClicked(position) {
      console.log('On map clicked:', position)
      let count = 1
      this.selectedProject.projectSitePlans.forEach(site => {
        let targetDevices = site.listOfPlanDevices.filter(device => device.type === this.deviceType)

        count += targetDevices.length
      })

      let newTargetDevices = this.devices.filter(device => device.type === this.deviceType)

      count += newTargetDevices.length

      let newDevice = {
        name: `${capitalizeFirstLetter(this.deviceType)} ${count}`,
        type: this.deviceType,
        rotation: 0,
        address: '',
        location: {
          coordinates: [position.lng, position.lat]
        },
        currentStatus: {name: DeviceStatus.CREATED.name, color: DeviceStatus.CREATED.color}
      }

      this.devices.push(newDevice)
    },
    getAddressString(location) {
      if (location.address) {
        if (location.place.includes(location.address)) {
          return `${location.place}`
        } else {
          return `${location.address}, ${location.place}`
        }
      } else {
        this.$buefy.toast.open({
          message: 'Can\'t get address for selected location',
          duration: 4000,
          type: 'is-danger'
        })
        return 'unknown'
      }
    },
    async onCoordinatesUpdated(data) {
      console.log('On update coordinates: ', data)
      let index = this.devices.findIndex(device => device.name === data.item.name)
      if (index !== -1) {
        console.log('Find item: ', this.devices[index])
        this.isLoading = true
        //let location = await this.getLocation(data.coordinates)
        this.isLoading = false
        this.devices[index].location.coordinates = [data.coordinates.lng, data.coordinates.lat]
        //this.devices[index].address = this.getAddressString(location)
      }
    },
    onRotationChanged(data) {
      console.log('On rotation changed', data)
      let index = this.devices.findIndex(device => device.name === data.item.name)
      if (index !== -1) {
        console.log('Find item rotation: ', this.devices[index])
        this.devices[index].rotation = data.rotation
      }
    },
    onDeviceLocationChanged(data) {
      console.log('Device location changed:', data)
      let coordinates = extractArrayGpsCoordinates(data.location)
      let index = this.devices.findIndex(device => device.name === data.device.name)
      if (index !== -1) {
        console.log('Find item: ', this.devices[index])
        this.devices[index].location.coordinates = coordinates
      }
    },
    onDeviceInstructionChanged(data) {
      let index = this.devices.findIndex(device => device.name === data.device.name)
      if (index !== -1) {
        console.log('Find item: ', this.devices[index], data.instruction)
        this.devices[index].instruction = data.instruction
      }
    },
    onDeviceDecommissionInstructionChanged(data) {
      let index = this.devices.findIndex(device => device.name === data.device.name)
      if (index !== -1) {
        console.log('Find item: ', this.devices[index], data.instruction)
        this.devices[index].decommissionInstruction = data.instruction
      }
    },
    onDeviceCollectionPeriodChanged(data){
      let index = this.devices.findIndex(device => device.name === data.device.name)
      if (index !== -1) {
        console.log('Find item: ', this.devices[index], data.collectionPeriod)
        this.devices[index].collectionPeriod = data.collectionPeriod
      }
    },
    onDeviceProcessingPeriodChanged(data){
      let index = this.devices.findIndex(device => device.name === data.device.name)
      if (index !== -1) {
        console.log('Find item: ', this.devices[index], data.processingPeriod)
        this.devices[index].processingPeriod = data.processingPeriod
      }
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    ...mapGetters({
      mapType: 'getMapType',
      selectedProject: 'projectsModule/getSelectedProject'
    }),
    DeviceType() {
      return DeviceType
    },
    hasCameraDevice() {
      let index = this.devices.findIndex(device => device.type === DeviceType.CAMERA)
      return index !== -1
    },
    hasAtcDevice() {
      let index = this.devices.findIndex(device => device.type === DeviceType.ATC)
      return index !== -1
    },
    hasRadarDevice() {
      let index = this.devices.findIndex(device => device.type === DeviceType.RADAR)
      return index !== -1
    },
  }
}
</script>

<style scoped>

</style>