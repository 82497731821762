<template>
  <div>
    <nav class="listing-group-full-width">
      <section>
        <div class="p-2 pl-5" style="cursor: pointer; background: none;">
          <div class="columns m-0 p-0 is-align-items-center">
            <div class="column p-0 is-2">
              <div class="is-size-7 has-text-grey-light">Site name</div>
              <div class="is-size-6 has-text-weight-semibold has-text-black">{{ item.siteName }}</div>
            </div>
            <div class="column p-0 is-2">
              <div class="is-size-7 has-text-grey-light" v-if="item.objectType === null">Device Name</div>
              <div class="is-size-6 has-text-weight-semibold has-text-black" v-if="item.objectType === null">{{
                  item.name
                }}
              </div>
            </div>
            <div class="column p-0 pl-2 pr-2 is-4">
              <b-field>
                <b-input ref="addressInput" v-model="address" @input="onAddressChanged" placeholder="Address"
                         rounded></b-input>
              </b-field>
            </div>
            <div class="column p-0 pl-2 pr-2 is-4">
              <b-field>
                <b-input v-model="location" @input="onCoordinatesChanged" placeholder="Location" rounded></b-input>
              </b-field>
            </div>
          </div>
        </div>
      </section>
    </nav>
    <div v-if="results.length > 0" class="address-results"
         :style="{'top': offsetTop + 'px', 'left':offsetLeft + 'px', 'width': width + 'px'}">
      <div v-for="(result, index) in results" :key="index">
        <div @click="onResultClicked(result)" class="p-1 pl-3 pr-3 result">{{ result.address }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "map-edit-location",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      address: null,
      location: null,
      offsetLeft: 0,
      offsetTop: 0,
      width: 300,
      results: [],
      timeout: null,
    }
  },
  mounted() {
    this.address = this.item.address
    this.location = this.item.location.coordinates.toString()

    console.log('Address input', this.$refs.addressInput)
    this.offsetLeft = this.$refs.addressInput.$el.offsetLeft + 8
    this.offsetTop = this.$refs.addressInput.$el.offsetTop + 42
    this.width = this.$refs.addressInput.$el.clientWidth - 16

    /*    setTimeout(() => {
          this.results = ['Result 1', 'Result 2', 'Result 3']
        }, 2000)*/
  },
  methods: {
    ...mapActions({
      getLocationByName: 'getLocationByName'
    }),
    async onAddressChanged(value) {
      console.log('On address changed: ', value)
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        if (value && value.length > 0) {
          console.log('Call service')
          let results = await this.getLocationByName(value)
          console.log('Geo location results:', results)
          this.results = results.map(result => {
            return {address: result.formatted_address, location: result.geometry.location}
          })
        }
      }, 1000)
    },
    onCoordinatesChanged(value) {
      console.log('On address changed: ', value)
    },
    onResultClicked(result) {
      console.log('On result clicked: ', result)
      this.address = result.address
      this.location = [result.location.lng, result.location.lat].toString()
      this.results = []
      let newDevice = this.item
      newDevice.address = this.address
      newDevice.location.coordinates = [result.location.lng, result.location.lat]
      this.$emit('onMapEditAddressChanged', newDevice)
    }
  }
}
</script>

<style lang="scss" scoped>
.simple-device-card {
  border-radius: 32px;
  background-color: #fdfdfd;
  box-shadow: 0 0.125em 0.125em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
}

.address-results {
  background: white;
  width: 128px;
  position: absolute;
  z-index: 1;
  box-shadow: 0 0.125em 0.125em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.result {
  cursor: pointer;
}

.result:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}
</style>