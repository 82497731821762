<template>
  <div>
    <transition name="fade" mode="out-in" v-if="selectedQaProject === null">
      <search-box-qa ref="searchBoxQa" style="margin-top: 150px"></search-box-qa>
    </transition>
    <g-map-qa @onMapClicked="onMapClicked" @onMapLoaded="onMapLoaded"></g-map-qa>
    <div v-if="selectedQaProject" class="pb-6">
      <div class="shadow"></div>
      <div>
        <div class="is-flex has-background-white title-box p-2">
          <div class="is-size-4 ml-5 mr-5 pt-1 pb-1 has-text-weight-bold">{{ selectedQaProject.project.name }}
          </div>
          <b-button class="card-header-icon ml-auto mt-auto mb-auto mr-2" aria-label="more options"
                    @click="closeProjectOverview">
            <b-icon icon="close"></b-icon>
          </b-button>
        </div>
        <transition name="fade">
        <div v-if="selectedDevice !== null" class="m-3">
          <device-info></device-info>
          <device-qa></device-qa>
          <qa-table class="m-2"></qa-table>
        </div>
        </transition>
        <transition name="fade">
        <div v-if="selectedDevice === null" style="height: 200px" class="is-flex is-flex-direction-column">
          <div class="is-flex is-align-items-center dashed-border mt-auto mb-auto ml-4 mr-4">
            <h4 class="is-size-4 ml-auto mr-auto has-text-grey-light has-text-weight-light">To start please select device</h4>
          </div>
        </div>
        </transition>

      </div>
    </div>
  </div>
</template>

<script>
import GMapQa from "@/modules/qa-module/components/g-map-qa/g-map-qa.vue";
import SearchBoxQa from "@/modules/qa-module/components/search-box-qa/search-box-qa.vue";
import {mapActions, mapGetters} from "vuex";
import DeviceQa from "@/modules/qa-module/components/device-qa/device-qa.vue";
import QaTable from "@/modules/qa-module/components/qa-table/qa-table.vue";
import DeviceInfo from "@/modules/qa-module/components/device-info/device-info.vue";

export default {
  name: "qa-page",
  components: {DeviceInfo, QaTable, DeviceQa, SearchBoxQa, GMapQa},
  methods: {
    ...mapActions({
      setSelectedQaProject: 'qaModule/setSelectedQaProject',
    }),
    onMapClicked() {
      console.log('On map clicked')
      if (this.$refs.searchBoxQa) {
        this.$refs.searchBoxQa.onClickedOutside()
      }

      console.log('Selected Qa Project: ', this.selectedQaProject)
    },
    onMapLoaded() {
      console.log('On map loaded')
    },
    closeProjectOverview() {
      this.setSelectedQaProject(null)
    }
  },
  computed: {
    ...mapGetters({
      selectedQaProject: 'qaModule/getSelectedQaProject',
      selectedDevice: 'qaModule/getSelectedQaDevice'
    }),
  }
}
</script>

<style scoped>
.title-box {
  background-color: hsl(0deg, 0%, 100%);
  border-radius: 0;
  box-shadow: 0 0.125em 0.5em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
  color: hsl(0deg, 0%, 29%);
  display: block;
  padding: 1.25rem;
}

.shadow {
  box-shadow: 0 0.125em 0.125em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%)
}
</style>