export default () => ({
    selectedQaProject: null,
    selectedQaDevice: null,
    searchQueryData: {
        query: '',
        sort: {icon: 'sort-alphabetical-ascending', text: 'Alphabetically ascending', value: 'ASCENDING'},
        projectType: {icon: '', text: 'All', value: 'ALL'},
        active: {icon: 'filter-off', text: 'All', value: 'ALL'}
    },
    qaData: [],
    qaTableHeaderData: {
        activeSorted: {
            field: '',
            order: ''
        },
        activeFilterField: '',
        filters: {avgSpeed: null, userClass: null, userType: null, eventAt:null}
    },
    qaLegendSelection:{PASS: {name:'Pass', value: true, color:'#48c78e'}, FAIL: { name:'Fail', value: true,color:'#f14668'},
        PASS_WITH_CHANGES: {name:'Pass With Changes', value: true,color:'#3e8ed0'}, NO_QA: {name:'No QA', value: true,color:'black'}}
});
