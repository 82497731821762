export default () => ({
    allProjects: [],
    selectedProject: null,
    selectedDevices: [],
    searchQueryData: {
        query: '',
        sort: {icon: 'sort-alphabetical-ascending', text: 'Alphabetically ascending', value: 'ASCENDING'},
        projectType: {icon: '', text: 'All', value: 'ALL'},
        active: {icon: 'filter-off', text: 'All', value: 'ALL'}
    },
    tableHeaderData: {
        activeSorted: {
            field: '',
            order: ''
        },
        activeFilterField: '',
        filters: {
            risk: [],
            pet: null,
            dca: null,
            date: null,
            time: null,
            ttc: null,
            kineticEnergy: null,
            userTypes: null
        }
    }
});
